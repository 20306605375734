import IndFlagIcon from '../../assets/images/indiaFlag.png';
import YoutubeIcon from '../../assets/icons/youtubeIcon.svg';
import InstaIcon from '../../assets/icons/instaIcon.svg';
import FaceBookIcon from '../../assets/icons/fbIcon.svg';
import LinkedInIcon from '../../assets/icons/linkedinIcon.svg';
import TwitterIcon from '../../assets/icons/twitterIcon.svg';
import Chat from './chat';

export default function Footer() {
  return (
    <div className="bg-black h-screen flex flex-col px-5 lg:gap-y-6 py-5 lg:px-10">
      <div className="hidden lg:block">
        <Chat />
      </div>
      <div className="lg:py-0 flex flex-col gap-y-10 lg:gap-y-0 lg:flex-row items-start">
        <div className="lg:w-[50%]">
          <p className="font-lato font-[500] text-white text-[30px] lg:text-[50px] font-[800]">
            buildatscale.
          </p>
          <p className="font-lato font-[500] text-white text-[18px] lg:text-[1.5em]">
            Our Presence
          </p>
          <div className="flex flex-col lg:flex-row lg:items-center mt-5 gap-y-4 lg:gap-x-10">
            <div className="flex items-center gap-x-4">
              <img
                src={IndFlagIcon}
                className="w-[40px] h-[40px] rounded-full object-cover"
              ></img>
              <div className="flex flex-col">
                <p className="text-textColor5 font-lato">India</p>
                <p className="text-textColor font-lato">
                  Unit no.111, Aggarwal City Square <br />
                  Sector-3, Rohini, New Delhi-110085
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[60%] flex flex-col lg:flex-row gap-y-5 lg:gap-y-10 lg:gap-x-20">
          <div className="w-[2px] h-[300px] bg-white hidden lg:flex"></div>
          <div className="flex flex-col gap-y-5 relative">
            <p className="text-white font-lato text-[22px] lg:text-[1.5em] font-[500]">
              Quick Links
            </p>
            <div className="flex flex-col gap-y-1 lg:gap-y-4">
              <a href="/blog/" className="font-lato text-textColor5 text-[1em]">
                Insights
              </a>
              <p className="font-lato text-textColor5 text-[1em]">
                Case Studies
              </p>
              <p className="font-lato text-textColor5 text-[1em]">
                Become an affiliate
              </p>
              <p className="font-lato text-textColor5 text-[1em]">
                Testimonials
              </p>
            </div>
          </div>
          <div className="w-[2px] h-[300px] bg-white hidden lg:flex"></div>
          <div className="flex flex-col gap-y-5 lg:gap-y-10">
            <p className="text-white font-lato text-[22px] lg:text-[1.5em] font-[500]">
              Follow us
            </p>
            <div className="">
              <div className="flex gap-x-4">
                <img src={YoutubeIcon}></img>
                <img src={FaceBookIcon}></img>
                <img src={InstaIcon}></img>
                <img src={LinkedInIcon}></img>
                <img src={TwitterIcon}></img>
              </div>
            </div>
            <div className="h-[2px] w-full bg-white"></div>
            <div className="flex flex-col gap-y-4">
              <p className="font-lato text-textColor5 font-[400] text-[1em]">
                Ph: +91 99 118 00399
              </p>
              <p className="font-lato text-textColor5 font-[400] text-[1em]">
                {/* Email: support@buildatscale.com */}
                Email: buildatscale@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-white lg:text-center text-[1em] py-4 lg:py-0 font-[400]">
        © buildatscale 2024
      </div>
    </div>
  );
}
