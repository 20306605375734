import { useState } from 'react';
import Heart from '../../assets/svgs/heart.svg';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/animations/logoAnimation.mp4';

export default function Navbar() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="left-0 right-0 flex items-center justify-between px-5 py-2 lg:py-5 lg:px-5 lg:px-10 bg-bgNav z-50 transition-all duration-300">
      <a href="/">
        <video
          autoPlay
          muted
          playsInline
          preload="auto"
          height={20}
          width={150}
        >
          <source src={logo} type="video/mp4"></source>
        </video>
      </a>

      {/* Desktop Menu */}
      <div className="hidden lg:flex items-center gap-x-10">
        <a href="https://buildatscale.com/blog/insights/">
          <p
            className={`font-lato font-[700] text-[22px] ${
              location.pathname === '/blog/' ? 'text-primary' : 'text-black'
            }`}
          >
            Insights
          </p>
          {location.pathname === '/blog/' && (
            <div className="w-[10px] h-[10px] mx-auto rounded-full bg-primary"></div>
          )}
        </a>
        <a href="https://buildatscale.com/blog/instagram/">
          <div className="flex gap-1">
            <p
              className={`font-lato font-[700] text-[22px] ${
                location.pathname === '/fame/' ? 'text-primary' : 'text-black'
              }`}
            >
              Wall Of{' '}
            </p>
            <img src={Heart} alt="Heart" />
          </div>
        </a>
        <a href="https://buildatscale.com/blog/contact-us/">
          <p className="font-lato font-[700] text-[22px]">Contact us</p>
        </a>
      </div>

      {/* Hamburger Menu for Mobile */}
      <div className="lg:hidden z-50" onClick={toggleMenu}>
        <div className={`hamburger ${isMenuOpen ? 'open' : ''}`}>
          <span className="line top"></span>
          <span className="line middle"></span>
          <span className="line bottom"></span>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`absolute top-[60px] left-0 right-0 bg-bgNav px-5 py-3 z-40 transition-all duration-500 transform ${
          isMenuOpen
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 -translate-y-full'
        } lg:hidden flex flex-col gap-4 items-center`}
      >
        <a href="/blog/">
          <p
            className={`font-lato font-[700] text-[18px] ${
              location.pathname === '/blog/' ? 'text-primary' : ''
            }`}
          >
            Insights
          </p>
        </a>
        <a href="/fame/">
          <div className="flex gap-1 items-center">
            <p
              className={`font-lato font-[700] text-[18px] ${
                location.pathname === '/fame/' ? 'text-primary' : ''
              }`}
            >
              Wall Of{' '}
            </p>
            <img src={Heart} alt="Heart" className="w-5 h-5" />
          </div>
        </a>
        <a href="/contact/">
          <p className="font-lato font-[700] text-[18px]">Contact us</p>
        </a>
      </div>
    </div>
  );
}
